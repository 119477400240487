<template>
	<transition name="fade">
		<div>
			<template v-if="openDialog">
				<ModalPagamentoBoleto
					id="modal2"
					:atrasado="atrasado"
					:copy-clipboard="cod_barras"
					@continuar="fcontinuar"
				>
					<template #header>
						<h1>ATENÇÃO:</h1>
					</template>
					<template #sbody>
						<p>
							A parcela original desta mensalidade foi cancelada pelo banco devido à data de vencimento.
							Ao clicar em continuar, esta parcela estará disponível novamente e atualizada de acordo com os valores e a nova data de vencimento
						</p>
					</template>
				</ModalPagamentoBoleto>
			</template>

			<ModalPagamentoBoleto
				id="modal"
				:copy-clipboard="cod_barras"
				@okfinal="okFinal"
			>
				<template #header>
					<h1>{{ titleModal }}</h1>
				</template>
				<template #sbody>
					<p><strong>Atenção: </strong> O pagamento realizado via boleto, pode levar até 3 dias úteis para aparecer no sistema</p>
				</template>
			</ModalPagamentoBoleto>

			<ModalPagamentoBoleto
				id="modalPix"
				:pagamento-via-pix="true"
				@gerarPixClick="gerarPixClick"
			>
				<template #header>
					<h1 class="titlePix">
						ATENÇÃO
					</h1>
				</template>
				<template #sbody>
					<strong>Você escolheu realizar um pagamento via PIX.</strong><br>
					<strong>Fique Atento:</strong>
					<ul class="circle-list">
						<li>Pode levar até {{ tempoPix }} minutos para que a compensação bancária (baixa no pagamento) ocorra. </li>
						<li>Durante o tempo de compensação bancária (baixa no pagamento), não será possível que você escolha outras opções de pagamento para mesma mensalidade. </li>
					</ul>
				</template>
			</ModalPagamentoBoleto>

			<ModalPagamentoBoleto
				id="modalPixConfirma"
				:pagamento-via-pix="true"
				:confirmacao="true"
				:loading-pix="loadingPix"
				:doc_id="doc_id"
				:tempo-pix="tempoPix"
				@gerarPixClick="gerarPixClick"
				@copiarPixClick="copiarPixClick"
			>
				<template #header>
					<h1 class="titlePix">{{ titleModal }}</h1>
				</template>
				<template #sbody>
					<div v-if="loadingPix">
						Aguarde carregando ...
					</div>
					<div v-else>
						<p class="has-text-weight-bold">Escolha como quer visualizar a chave para pagamento PIX:</p>
						<p class="has-text-weight-bold has-text-danger">Tem certeza que deseja efetuar o pagamento via PIX?</p>
					</div>
				</template>
			</ModalPagamentoBoleto>

			<ModalPagamentoBoleto
				id="modalPixGerar"
				:pagamento-via-pix="true"
				:confirmacao="false"
				:pagamento-gerado="true"
				:loading-pix="loadingPix"
				:doc_id="doc_id"
				:tempo-pix="tempoPix"
				@close="onModalClose"
				@pixGerado="handlePixGerado"
			>
				<template #header>
					<h1 class="titlePix">{{ titleModal }}</h1>
				</template>
				<template #sbody>
					<strong>Escaneie o QR Code para pagar com PIX:</strong>
					<div v-if="loadingPix">
						Aguarde carregando ...
					</div>
					<div v-else>
						<img
							class="img_pix_qrcode"
							alt="QR Code PIX"
							:src="dadosPix.qrcode"
						>
						<!-- <div class='truncate-text'>{{ dadosPix.doc_codigo_pix }}</div> -->
					</div>
				</template>
			</ModalPagamentoBoleto>

			<ModalPagamentoBoleto
				id="modalPixFinal"
				:pagamento-via-pix="true"
				:pagamento-gerado="true"
				:pix-final="true"
				:doc_id="doc_id"
				:tempo-pix="tempoPix"
				@close="onModalClose"
				@pixGerado="handlePixGerado"
			>
				<template #header>
					<h1 class="titlePix">
						IMPORTANTE
					</h1>
				</template>
				<template #sbody>
					<strong>Você acabou de gerar um QRCode/Chave PIX para pagamento.</strong>
					<ul class="circle-list">
						<li>A compensação bancária (baixa no pagamento) pode ocorrer em até {{ tempoPix }} minutos. </li>
						<li>Enquanto isso, sua mensalidade continua na aba atual aguardando a compensação bancária (baixa no pagamento) e não ficará disponível para outras formas de pagamento.</li>
						<li>Assim que o pagamento for confirmado, a consulta da mensalidade ficará disponível na aba "Pagos".</li>
					</ul>
				</template>
			</ModalPagamentoBoleto>

			<b-notification
				:closable="false"
				class="notification"
			>
				<b-loading
					v-model="loading"
					:is-full-page="true"
					:can-cancel="false"
				/>
			</b-notification>

			<hr
				v-if="data.length > 0"
				class="divider-horizontal"
			>

			<div
				v-if="data.length == 0"
				class="py-5 is-flex is-justify-content-center"
			>
				<div
					v-if="listType === 'abertos'"
					class="is-flex is-flex-direction-column is-align-items-center"
				>
					<h4 class="is-size-3 is-size-4-mobile has-text-centered justify-with-image mt-2">
						Suas parcelas ficarão disponíveis para pagamento, conforme vínculo que você selecionou, em até 3 dias antes do vencimento.
					</h4>
					<p class="column is-10 is-size-5 has-text-centered">
						Não se preocupe, quando ficarem disponíveis elas aparecerão aqui. <br> Fique ligado! 😉
					</p>
					<div class="column is-5 has-text-centered">
						<Icon
							class="img-vencer"
							file="hourglass"
							size="30%"
						/>
					</div>
					<p class="column is-9 is-size-5 has-text-centered">
						<span v-if="showAtrasados">Clique na aba “<a
							class="link-atraso"
							href="#"
							@click="goToTab(0)"
						>Em Atraso</a>” para verificar se não há pagamentos atrasados. <br></span>
						<span v-if="showPagos">Clique na aba “<a
							class="link-pagos"
							href="#"
							@click="goToTab(2)"
						>Pagos</a>” para conferir todos os pagamentos já realizados.</span>
					</p>
				</div>
			</div>

			<div
				v-for="(boleto, index) in data"
				:key="boleto.doc_id"
				class="pt-3"
			>
				<div class="columns is-desktop py-4">
					<div class="column is-6-desktop is-12 is-flex is-flex-direction-column">
						<article
							:class="'notification notification-new notfy-' + index"
							style="display: none"
							@mouseover="showInfo(index);"
							@mouseleave="closeInfo(index)"
						>
							<button
								type="button"
								class="delete"
								@click="closeInfo(index)"
							/>
							<div class="media">
								<div class="media-content">
									<div>
										Em caso de rescisão contratual por qualquer uma das partes (aluno ou universidade), as parcelas de
										competência sobre o mês do pedido de rescisão serão devidas para pagamento do aluno.<br>
										Acesse o Contrato de Prestação de Serviços Educacionais em seu Painel de estudante no menu
										'Contratos' para mais informações.
									</div>
								</div>
							</div>
						</article>
						<div
							:class="[
								{ overdue: isOverdue(boleto.data_vencimento) },
								'open',
								'center-on-mobile',
							]"
						>
							<b-icon
								size="is-small"
								icon="alert-circle-outline"
							/>
							<span
								v-if="!boleto.espera_operadora"
								class="is-size-6 has-text-weight-bold"
							>
								{{
									isOverdue(boleto.data_vencimento)
										? 'Fatura em atraso'
										: 'Fatura em aberto'
								}}
							</span>
							<span
								v-if="boleto.espera_operadora"
								class="is-size-6 has-text-weight-bold"
							>
								Pagamento em processamento
							</span>
						</div>

						<p class="is-size-4 center-on-mobile has-text-weight-bold pb-4">
							R${{ boleto.valor_titulo }}
						</p>
						<p class="is-size-6">
							Vencimento:
							<strong>
								{{ parseDate(boleto.data_vencimento_regerado ? boleto.data_vencimento_regerado : boleto.data_vencimento ) }}
							</strong>
							<small v-if="boleto.data_vencimento_regerado != null && boleto.data_vencimento_regerado != undefined && boleto.data_vencimento_regerado != ''"> (Regerado em <strong>{{ boleto.data_geracao_boleto }}</strong>) </small>
						</p>

						<p class="is-size-6">
							Curso: <strong>{{ boleto.curs_nome }}</strong>
						</p>
						<p class="is-size-6">
							Período letivo: <strong>{{ boleto.periodo_letivo }}</strong>
						</p>

						<p
							v-if="boleto.doc_tipo.toLowerCase() == 'm'"
							class="is-size-6"
						>
							Mês referência:
							<strong>{{ boleto.mes_referencia.replace('/', ' / ') }}</strong>
							<button
								class="btn-invisible"
								@mouseover="showInfo(index);"
								@mouseleave="closeInfo(index)"
							>
								<b-icon
									class="primary-color icon-info"
									icon="information-outline"
									@mouseover="showInfo(index);"
									@mouseleave="closeInfo(index)"
								/>
							</button>
						</p>

						<p
							v-if="boleto.doc_tipo.toLowerCase() != 'm'"
							class="is-size-6"
						>
							Tipo:
							<strong>
								{{
									boleto.doc_tipo_parse
										.toLowerCase()
										.split(' ')
										.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
										.join(' ')
								}}
							</strong>
						</p>

						<DetalhesFatura
							:data="boleto.detalhes"
							:doc-id="boleto.doc_id"
							:data-original="boleto.data_vencimento_regerado ? boleto.data_vencimento.split('-').reverse().join('/') : false"
						/>
					</div>
					<div class="column vertical-divider is-1-desktop is-hidden-touch" />

					<div class="column is-5-desktop is-12 is-flex is-flex-direction-column is-align-items-center">
						<div
							v-if="listType === 'abertos' && boleto.recorrencia_ativa && boleto.doc_tipo === 'M'"
							class="is-flex is-flex-direction-column is-align-items-center"
						>
							<b-icon
								icon="credit-card-refresh"
								class="icon-recorrence"
								custom-size="icon-72px"
							/>

							<span class="pt-2 pb-4">Pagamento programado no cartão de crédito</span>

							<b-button
								type="is-danger"
								rounded
								outlined
								@click="showRecurrenceDisableCartaoModal = true"
							>
								Cancelar pagamento recorrente
							</b-button>
						</div>

						<b-tooltip
							v-else
							class="tooltip-em-aberto"
							animated
							multilined
							position="is-bottom"
							:active="!(boleto.bloqueio.permitir_boleto
								|| boleto.bloqueio.permitir_regerar)
								|| (!boleto.bloqueio.permitir_pix && boleto.pix_habilitado)
								|| !boleto.bloqueio.permitir_cartao"
							:label="boleto.bloqueio.msg_bloqueio"
							always
						>
							<b-button
								v-if="boleto.pix_habilitado"
								:id="'botao_pix' + boleto.doc_id"
								:disabled="!(boleto.bloqueio.permitir_pix) || disablePaymentButtons"
								class="mb-4 button is-fullwidth is-primary is-rounded buttonbreak pix"
								@mouseover="getTooltipText(boleto.doc_id)"
								@touchstart="getTooltipText(boleto.doc_id)"
								@mouseleave="getTooltipTextOff(boleto.doc_id)"
								@touchend="getTooltipTextOff(boleto.doc_id)"
								@click="onOpenDialogPix(boleto.doc_id, boleto.tempo_pix),
									setTitleModal('Pagamento com PIX'),
									setTempoPix(boleto.tempo_pix)"
							>
								PIX
							</b-button>
							<span
								:id="'bloqueio_pix' + boleto.doc_id"
								class="bloqueio_pix"
							>
								Você gerou uma chave para pagamento via PIX.<br>
								Aguarde a confirmação bancária em até {{ boleto.tempo_pix }} minutos para realizar a
								escolha e optar por um novo pagamento desta mensalidade.
							</span>
							<b-button
								v-if="!boleto.espera_operadora"
								:id="'botao_cartao_credito' + boleto.doc_id"
								:disabled="!boleto.bloqueio.permitir_cartao || disablePaymentButtons"
								class="mb-4 is-fullwidth button is-primary is-rounded buttonbreak"
								@mouseover="getTooltipText(boleto.doc_id)"
								@mouseleave="getTooltipTextOff(boleto.doc_id)"
								@touchstart="getTooltipText(boleto.doc_id)"
								@touchend="getTooltipTextOff(boleto.doc_id)"
								@click="payWithCard(boleto)"
							>
								Cartão de Crédito
							</b-button>
							<b-button
								:id="'botao_boleto' + boleto.doc_id"
								:disabled="!(boleto.bloqueio.permitir_boleto || boleto.bloqueio.permitir_regerar) || disablePaymentButtons"
								class="mb-4 button is-primary is-fullwidth is-rounded buttonbreak"
								@mouseover="onOpenDialogAtrasado(boleto.bloqueio.permitir_regerar), getTooltipText(boleto.doc_id)"
								@mouseleave="getTooltipTextOff(boleto.doc_id)"
								@touchstart="getTooltipText(boleto.doc_id)"
								@touchend="getTooltipTextOff(boleto.doc_id)"
								@click="onOpenDialog(boleto.doc_id,
									boleto.bloqueio.permitir_boleto,
									boleto.bloqueio.permitir_regerar), regerarAtrasado(boleto.bloqueio.permitir_regerar)"
								@click.prevent="setTitleModal('Download em andamento')"
							>
								Gerar boleto
							</b-button>
							<b-button
								:id="'botao_codigo_barras' + boleto.doc_id"
								:disabled="!(boleto.bloqueio.permitir_boleto || boleto.bloqueio.permitir_regerar) || disablePaymentButtons"
								class="mb-4 button is-fullwidth is-primary is-rounded is-outlined buttonbreak"
								@mouseover="onOpenDialogAtrasado(boleto.bloqueio.permitir_regerar), getTooltipText(boleto.doc_id)"
								@mouseleave="getTooltipTextOff(boleto.doc_id)"
								@touchstart="getTooltipText(boleto.doc_id)"
								@touchend="getTooltipTextOff(boleto.doc_id)"
								@click="onOpenDialog(boleto.doc_id,
									boleto.bloqueio.permitir_boleto,
									boleto.bloqueio.permitir_regerar,
									boleto.doc_codigo_barras),
									setTitleModal('Código de barras copiado com sucesso'),
									regerarAtrasado(boleto.bloqueio.permitir_regerar)"
							>
								Copiar código de barras
							</b-button>
							<b-button
								:id="'botao_nota_fiscal' + boleto.doc_id"
								:disabled="!(boleto.nota_fiscal.link_retorno || boleto.nota_fiscal.mensagem)"
								class="mb-4 button is-fullwidth is-primary is-rounded is-outlined"
								@click.prevent="openLinkOrError(boleto.nota_fiscal.link_retorno, boleto.nota_fiscal.mensagem, boleto.nota_fiscal)"
							>
								Nota Fiscal
							</b-button>
						</b-tooltip>
					</div>
				</div>
				<hr class="divider-horizontal">
			</div>
			<b-modal
				v-if="modalData"
				:active="true"
				:is-full-page="true"
				:can-cancel="false"
			>
				<b-loading
					v-model="loading"
					:is-full-page="true"
					:can-cancel="false"
				/>
				<div class="card">
					<div class="card-content">
						<h1 class="is-flex title is-1 has-text-centered">
							Nota Fiscal
						</h1>
						<p>
							<strong>Informações para nota fiscal.</strong><br>
							Informamos que a nota fiscal está disponível para impressão.<br>
							Acesse em:
						</p>
						<a
							:href="modalData.link_retorno"
							target="_blank"
						>
							{{ modalData.link_retorno }}
						</a>
						<p>
							<strong>Algumas informações abaixo podem ser necessárias para acesso:</strong>
						</p>
						<ul>
							<li class="nf-list-item">
								<strong>CNPJ Prestador:</strong> {{ modalData.cnpjPrestador }}
							</li>
							<li class="nf-list-item">
								<strong>IM Prestador:</strong> {{ modalData.imPrestador }}
							</li>
							<li class="nf-list-item">
								<strong>Número NFS-e:</strong> {{ modalData.numeroNf }}
							</li>
							<li class="nf-list-item">
								<strong>Data de emissão:</strong> {{ modalData.dataEmissao }}
							</li>
							<li class="nf-list-item">
								<strong>Número do RPS:</strong> {{ modalData.numeroRps }}
							</li>
							<li class="nf-list-item">
								<strong>Série RPS:</strong> {{ modalData.serieRps }}
							</li>
							<li class="nf-list-item">
								<strong>Código de verificação:</strong> {{ modalData.codigoAutenticacao }}
							</li>
							<li class="nf-list-item">
								<strong>Valor do Serviço:</strong> {{ modalData.valorServico }}
							</li>
						</ul>
						<b-button
							class="mb-2 mt-5"
							label="Fechar"
							type="is-primary is-rounded"
							@click="closeNfNotification"
						/>
					</div>
				</div>
			</b-modal>

			<CartaoModalRecurrenceDisable
				v-if="showRecurrenceDisableCartaoModal"
				@confirm="disableRecurrence"
				@close="showRecurrenceDisableCartaoModal = false"
			/>
		</div>
	</transition>
</template>

<script>
import moment from 'moment';
import Icon from '@components/Icon';
import ModalPagamentoBoleto from '../notification/ModalPagamentoBoleto.vue';
import DetalhesFatura from '@components/meus_pagamentos/DetalhesFatura.vue';
import CartaoModalRecurrenceDisable from '@components/meus_pagamentos/pagamento/CartaoModalRecurrenceDisable';
import { mapActions } from 'vuex';

const padStartWithZero = (num) => num.toString(10).padStart(2, '0');

export default {
	name: 'ListaPagamentosPendentes',
	components: {
		Icon,
		ModalPagamentoBoleto,
		DetalhesFatura,
		CartaoModalRecurrenceDisable,
	},
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		listType: {
			type: String,
			required: true,
			default: '',
		},
		showAtrasados: {
			type: Boolean,
			required: false,
			default: false,
		},
		showPagos: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			openDialog: false,
			openDialogAtrasado: false,
			titleModalAtrasado: '',
			titleModal: '',
			textModal: '',
			atrasado: '',
			continuarRegerarBoleto: '',
			doc_id: '',
			permitir_boleto: '',
			permitir_regera: '',
			aceitou: false,
			cod_barras: '',
			modalData: null,
			doc_codigo_pix: null,
			qrcode: null,
			loadingPix: false,
			dadosPix: null,
			disablePaymentButtons: false,
			isModalVisible: false,
			tempoPix: '',
			showRecurrenceDisableCartaoModal: false,
		};
	},
	watch: {
		isModalVisible(newValue) {
			if (!newValue) {
				this.checkBlock(this.doc_id);
			}
		}
	},
	mounted() {
		const docsPixAtivados = localStorage.getItem('docsPixAtivados');

		if (docsPixAtivados) {
			const docIds = docsPixAtivados.split(',');
			docIds.forEach(doc_id => {
				this.checkBlock(doc_id);
			});
		}
	},
	methods: {
		...mapActions('pagamentos', [
			'gerarBoleto',
			'gerarComprovanteCartao',
			'regerarBoleto',
			'copiarClipboard',
			'gerarPix'
		]),
		goToTab(index) {
			this.$emit("goToTab", index);
		},
		regerarAtrasado(txt) {
			this.atrasado = txt
		},
		fcontinuar() {
			this.aceitou = true;
			this.openDialog = false;
			this.$root.$emit('open-modal', 'modal');
		},
		okFinal(download, copy) {
			if(download) {
				this.handleInvoice(this.doc_id, this.permitir_boleto, this.permitir_regera);
			} else if(copy) {
				this.copyToClipboard(this.permitir_boleto, this.permitir_regera, this.doc_id);
			}
		},
		setTitleModal(txt) {
			this.titleModal = txt
		},
		setTempoPix(tempo) {
			this.tempoPix = tempo
		},
		setTextModal(txt) {
			this.textModal = txt
		},
		onOpenDialog(doc, boleto, regerar = false, cod_barras = '') {
			this.doc_id = doc;
			this.permitir_boleto = boleto;
			this.permitir_regera = regerar;
			this.cod_barras = cod_barras;

			if(regerar) {
				this.$root.$emit('open-modal2', 'modal2');
			} else {
				this.$root.$emit('open-modal', 'modal');
			}

		},
		onOpenDialogAtrasado(regerar) {
			if(regerar) {
				this.openDialog = true;
			}
		},
		onOpenDialogPix(doc, tempoPix, doc_codigo_pix = '') {
			this.doc_id = doc;
			this.tempoPix = tempoPix;
			this.doc_codigo_pix = doc_codigo_pix;
			this.$root.$emit('open-modal', 'modalPix');
		},
		payWithCard(boleto) {
			if (!boleto.bloqueio.permitir_cartao) {
				return;
			}

			this.$store.commit('setFatura', boleto);
			this.$router.push({
				name: 'pagamento-cartao',
				params: { fatura: boleto },
			});
		},
		parseDate(date) {
			return moment(date).format('DD/MM/YYYY');
		},
		isOverdue(date) {
			const now = new Date();
			const today =
				now.getFullYear() +
				'-' +
				padStartWithZero(now.getMonth() + 1) +
				'-' +
				padStartWithZero(now.getDate());

			return new Date(date).getTime() < new Date(today).getTime();
		},
		async copyToClipboard(permitir = true, regerar, id) {

			if(regerar){
				const response = await this.copiarClipboard(id);

				const copy = navigator.clipboard.writeText(response);

				if (copy) {
					this.$buefy.toast.open({
						message: 'Código de barras copiado com sucesso!',
						type: 'is-success',
					});
				}
				return ;
			}

			if(permitir) {
				const copy = navigator.clipboard.writeText(this.cod_barras);

				if (copy) {
					this.$buefy.toast.open({
						message: 'Código de barras copiado com sucesso!',
						type: 'is-success',
					});
				}
				return ;
			}

		},
		async handleInvoice(id, permitir = true, regerar) {

			if(regerar) {
				const response = await this.regerarBoleto(id);

				if (response) {
					const res = await this.gerarBoleto(response);
					if (res) return;
				}
			}

			if(permitir) {
				const response = await this.gerarBoleto(id);

				if (response) return;
			}


			this.$buefy.toast.open({
				message: 'Erro ao gerar boleto!',
				type: 'is-danger',
			});

		},
		async gerarPixClick() {
			this.loadingPix = true; // Iniciar o carregamento
			const response = await this.gerarPix(this.doc_id);
			if (response) {
				this.dadosPix = response;
			} else {
				this.$buefy.toast.open({
					message: 'Erro ao gerar boleto!',
					type: 'is-danger',
				});
			}
			this.loadingPix = false; // Finalizar o carregamento
		},
		async copiarPixClick() {
			if (this.dadosPix && this.dadosPix.doc_codigo_pix) {
				navigator.clipboard.writeText(this.dadosPix.doc_codigo_pix).then(() => {
					this.$buefy.toast.open({
						message: 'Código de pix copiado com sucesso!',
						type: 'is-success',
					});
				});
			} else {
				console.error('dadosPix ou doc_codigo_pix não definido.');
			}
		},
		showInfo(index) {
			document.querySelectorAll('.notfy-' + index).forEach(el => {
				el.style.display = 'block';
			});
		},
		closeInfo(index) {
			document.querySelectorAll('.notfy-' + index).forEach(el => {
				el.style.display = 'none';
			});
		},
		openLinkOrError(link, error, infos) {
			if (error) {
				this.$store.dispatch('ui/toast/openError', error);

				return;
			}

			this.modalData = {
				link_retorno: infos.link_retorno,
				cnpjPrestador: infos.cnpj_prestador,
				imPrestador: infos.im_prestador,
				numeroNf: infos.numero_nf,
				dataEmissao: infos.data_emissao,
				numeroRps: infos.numero_rps,
				serieRps: infos.serie_rps,
				codigoAutenticacao: infos.codigo_verificacao,
				valorServico: infos.valor_nota
			};
		},
		closeNfNotification() {
			this.modalData = null;
		},
		checkBlock(docId) {
			const releaseTime = localStorage.getItem('releaseTime'+docId);
			if (releaseTime) {
				const currentTime = new Date().getTime();
				if (currentTime <= releaseTime) {
					//this.disablePaymentButtons = true;
					document.getElementById('botao_pix'+docId).disabled = true;
					document.getElementById('botao_cartao_credito'+docId).disabled = true;
					document.getElementById('botao_boleto'+docId).disabled = true;
					document.getElementById('botao_codigo_barras'+docId).disabled = true;
				} else {
					this.disablePaymentButtons = false;
					localStorage.removeItem('releaseTime'+docId);
				}
			}
		},
		clearBlock(docId) {
			localStorage.removeItem('releaseTime'+docId);
			this.disablePaymentButtons = false;
		},
		onModalClose() {
			this.checkBlock(this.doc_id);
		},
		handlePixGerado(value) {
			this.disablePaymentButtons = value;
		},
		isButtonDisabled(docId) {
			const docsPixAtivados = localStorage.getItem('docsPixAtivados');
			if (docsPixAtivados) {
				const docIds = docsPixAtivados.split(',');
				for (let storedDocId of docIds) {
					if (storedDocId === docId) {
						const releaseTime = localStorage.getItem('releaseTime' + docId);
						if (releaseTime) {
							const currentTime = new Date().getTime();
							if (currentTime <= releaseTime) {
								document.getElementById('bloqueio_pix' + docId).style.display = "block";
								return true;
							}
						}
					}
				}
			}
			return false;
		},
		getTooltipText(docId) {
			this.isButtonDisabled(docId);
			return;
		},
		getTooltipTextOff(docId) {
			document.getElementById('bloqueio_pix'+docId).style.display = "none";
			return;
		},

		async disableRecurrence() {
			try {
				this.loading = true;

				await this.$store.dispatch('pagamentos/desativarRecorrencia');

				this.showRecurrenceDisableCartaoModal = false;

				this.$store.dispatch(
					'ui/toast/openSuccess',
					'Pagamento em recorrência desabilitado'
				);

				this.$store.dispatch('pagamentos/todosPagamentos');
			} catch (error) {
				this.$store.dispatch(
					'ui/toast/openError',
					'Não foi possível desativar a recorrência agora, tente novamente mais tarde'
				);
			}
		},
	},
};
</script>

<style scoped>
.fade-enter-active {
	transition: opacity 1s;
}

.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
	{
	opacity: 0;
}

hr {
	background-color: #c2c2c2;
	height: 1px !important;
}

.open {
	color: #ffb100;
}

.overdue {
	color: #ff1515;
}

.buttonbreak {
	padding: 1rem !important;
	white-space: break-spaces !important;
	height: auto !important;
	padding-left: 3rem !important;
	padding-right: 3rem !important;
}

.notification-new {
	background: white;
	z-index: 9999;
	max-width: 72%;
	border: 1px #000 solid;
	text-align: justify;
	text-justify: inter-word;
}

.btn-invisible {
	border: unset;
	vertical-align: bottom;
	color: #c2c2c2;
	background: unset;
}

.icon-info {
	color: #b5b5b5;
	cursor: pointer;
}

@media only screen and (max-width: 600px) {
	.notification-new {
		width: 110%;
		left: -5%;
		max-width: 110%;
	}
}

@media (max-width: 1020px) {
	.center-on-mobile {
		text-align: center;
	}

	.left-on-mobile {
		text-align: left;
		padding-left: 0px !important;
	}
}

.justify-with-image {
	margin-top: -40px;
	line-height: 50px;
	color: #26496c;
	font-weight: bold;
}
</style>

<style>
.tooltip-em-aberto .tooltip-content {
	background-color: var(--primary) !important;
}

.img-vencer .hourglass-sand {
	fill: #f6cb5c !important;
}

.img-vencer .hourglass-base {
	fill: #9f7509 !important;
}

.img-vencer .hourglass-glass {
	fill: #d3d3d3 !important;
}

.img-vencer .hourglass-reflex {
	fill: #fff2c7 !important;
}

.link-atraso, .link-atraso:hover, .link-atraso:focus{
	font-weight: 700;
	color: #ff1515 !important;
}

.link-pagos, .link-pagos:hover, .link-pagos:focus{
	font-weight: 700;
	color: #00ae8e !important;
}

.link-atraso:hover, .link-atraso:focus, .link-pagos:hover, .link-pagos:focus{
	filter: brightness(40%) !important;
}
.nf-list-item {
	list-style-type: disc;
	margin-left: 20px;
}
.circle-list {
	list-style-type: disc; /* Use 'disc' para bolinhas */
	padding-left: 20px; /* Espaçamento para ajustar a bolinha */
}

.pix span {
	min-width: 185px;
}

.truncate-text {
	max-width: 200px; /* ou o valor que você deseja */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.img_pix_qrcode{
	max-width: 50%;
}

.dialog {
	width: 380px;
	height: 400px;
}

.titlePix {
	font-weight: bold;
	font-size: 24px;
	color: #26496C;
}

.bloqueio_pix {
	position: absolute;
	display: none;
	background-color: #E7E9EC;
	color: #000;
	padding: 5px;
	z-index: 999;
}

.icon-recorrence {
	width: 72px;
	height: 72px;
	line-height: 72px;
}

.icon-72px {
	font-size: 72px;
	line-height: 1rem;
}
</style>
