<style scoped>
.title-bar {
  line-height: 57px;
}

h2.title {
  margin: auto 0;
  display: inline-block;
  vertical-align: middle;
}

hr {
  height: 2px;
  margin: 5px 0 16px;
  background-color: #d5d6d8;
}

.title-id strong {
  color: #173048;
}
</style>

<template>
	<div class="mb-6">
		<div class="title-bar columns">
			<div class="column is-6 pb-0 mb-0">
				<h2 class="title is-1 mx-5 mt-1">
					<slot />
				</h2>
			</div>
			<div
				v-if="hasManyCourses"
				class="column is-6 pb-0 mb-0"
			>
				<FiltroVinculo />
			</div>
		</div>

		<hr>

		<div class="title-id mx-5">
			<span class="mr-6">Nome: <strong>{{ nome }}</strong></span>

			&nbsp;<br class="is-hidden-tablet">

			<span>Matrícula: <strong>{{ matricula }}</strong></span>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FiltroVinculo from '@components/FiltroVinculo';

export default {
	name: 'Title',
	components: {
		FiltroVinculo
	},
	computed: {
		...mapState({
			nome: state => {
				const nomes = state.alunoNome.split(' ');
				nomes.splice(1, nomes.length - 2);
				return nomes.join(' ');
			},
			matricula: state => state.alunoMatricula,
		}),
		...mapGetters('cursos', [
			'getCursos'
		]),
		hasManyCourses() {
			return this.getCursos.length > 1;
		}
	}
}
</script>
